import axios from 'axios';

export default {
  mounted() {
    const vm = this;
    this.httpclient = axios.create();
    this.httpclient.interceptors.request.use(
      function (config) {
        vm.isBusy = true;
        return config;
      },
      function (error) {
        // Do something with request error
        vm.isBusy = false;
        return Promise.reject(error);
      }
    );
    this.httpclient.interceptors.response.use(
      function (response) {
        vm.isBusy = false;
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          window.location.href =
            '/identity/account/login?ReturnUrl=%2F' + vm.path;
        } else if (error.response.status === 403) {
          window.location.href =
            '/identity/account/accessdenied?ReturnUrl=%2F' + vm.path;
        } else {
          let message = 'Failed. Please refresh the page and try again.';
          if (error.response.data && error.response.data.message) {
            message = error.response.data.message;
          }
          if (error.response.data && error.response.data.errorMessage) {
            message = error.response.data.errorMessage;
          }
          vm.isBusy = false;
          vm.$bvModal.msgBoxOk(message, {
            okVariant: 'danger',
            centered: true,
          });
        }
        return Promise.reject(error);
      }
    );
  },
  data() {
    return {
      isBusy: false,
      httpclient: null,
      path: '',
    };
  },
  methods: {},
};
